import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    agents: [],
    agent: null,
    loading: false,
    error: false,
};

const fetchAgentStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const fetchAgentSuccess = ( state, action ) => {
    return updateObject( state, {
        agents: action.agents,
        loading: false
    } );
};

const fetchAgentFail = ( state, action ) => {
    return updateObject( state, { loading: false } );
};


const removeAgentSuccess = (state, action) => {
    const index = state.agents.findIndex(element => element.id === action.agent.id)
    const newPc = [...state.agents]
    newPc.splice(index, 1);
    return (state , {
        agents: newPc,
        loading: false
    })
}

const ActivateDesactivateAgentSuccess = ( state, action ) => {
    return updateObject( state, {
        agents: state.agents.map(obj => {
            return action.agent._id === obj.id ? action.agent : obj
        }),
        loading: false
    } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.FETCH_AGENT_START: return fetchAgentStart( state, action );
        case actionTypes.FETCH_AGENT_FAIL: return fetchAgentFail(state, action);
        case actionTypes.FETCH_AGENT_SUCCESS: return fetchAgentSuccess(state, action);
        case actionTypes.ACTIVE_AGENT_SUCCESS: return ActivateDesactivateAgentSuccess(state, action);
        
        case actionTypes.REMOVE_AGENT_SUCCESS: return removeAgentSuccess(state, action);
        default: return state;
    }
};

export default reducer;
