
import React, {Component} from 'react'
import asyncComponent from './hoc/asyncComponent/asyncComponent';
import { Route, Switch, withRouter,HashRouter, Redirect } from 'react-router-dom';
import * as actions from './store/actions/index';
import { connect } from 'react-redux';
import Logout from './containers/auth/Logout/Logout'
import './style.scss'
import SignUp from './containers/SignUp';

const asyncAuth = asyncComponent(() => {
  return import('./containers/auth/auth');
});

const asyncSignUp = asyncComponent(() => {
  return import('./containers/SignUp/index');
});

const asyncForgotPassword = asyncComponent(() => {
  return import('./containers/auth/ForgotPassword/index');
});

const Layout = React.lazy(()=> import('./hoc/Layout/Layout'))

class App extends Component {

  componentDidMount () {
    this.props.onTryAutoSignup();
  }

render(){
  let routes 
  if (this.props.isAuthenticated == false){
  // if (this.props.isAuthenticated == true){
    routes = (
     <Switch>
      <Route path="/auth" component={asyncAuth} />
      <Route path="/signUp" component={asyncSignUp}/>
      <Route path="/forgot" component={asyncForgotPassword}/>
     <Redirect to='/auth'></Redirect>
     </Switch>
   )
   }
   else {
     routes = ( 
       <Switch>
       <Route path="/"  name="Home"  render={props => <Layout {...props}/>} /> 
       </Switch>
     )
   }
  return (
    <React.Suspense fallback="">
      {routes}
    </React.Suspense>
  );
}
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch( actions.authCheckState() )
  };
};

export default withRouter(connect( mapStateToProps, mapDispatchToProps )( App ));
