

export const FETCH_AGENT_START = 'FETCH_AGENT_START';
export const FETCH_AGENT_SUCCESS = 'FETCH_AGENT_SUCCESS';
export const FETCH_AGENT_FAIL = 'FETCH_AGENT_FAIL';

export const FETCH_ESTIMATION_START = 'FETCH_ESTIMATION_START';
export const FETCH_ESTIMATION_SUCCESS = 'FETCH_ESTIMATION_SUCCESS';
export const FETCH_ESTIMATION_FAIL = 'FETCH_ESTIMATION_FAIL';
export const REMOVE_ESTIMATION_SUCCESS = 'REMOVE_ESTIMATION_SUCCESS';
export const COMMENT_ESTIMATION_SUCCESS = 'COMMENT_ESTIMATION_SUCCESS';

export const FETCH_LEAD_START = 'FETCH_LEAD_START';
export const FETCH_LEAD_SUCCESS = 'FETCH_LEAD_SUCCESS';
export const FETCH_LEAD_FAIL = 'FETCH_LEAD_FAIL';
export const REMOVE_LEAD_SUCCESS = 'REMOVE_LEAD_SUCCESS';
export const COMMENT_LEAD_SUCCESS = 'COMMENT_LEAD_SUCCESS';

export const REMOVE_AGENT_SUCCESS = 'REMOVE_CLIENT_SUCCESS';
export const ACTIVE_AGENT_SUCCESS = 'ACTIVE_AGENT_SUCCESS'
export const SEND_MAIL_SUCCESS = 'SEND_MAIL_SUCCESS'


export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

