import axios from 'axios'
import * as actionTypes from './actionTypes';

export const fetchEstimationSuccess = (estimations) => {
    return {
        type: actionTypes.FETCH_ESTIMATION_SUCCESS,
        estimations: estimations
    };
};

export const fetchEstimationFail = (error) => {
    return {
        type: actionTypes.FETCH_ESTIMATION_FAIL,
        error: error
    };
};


export const fetchEstimationStart = () => {
    return {
        type: actionTypes.FETCH_ESTIMATION_START
    };
};

export const removeEstimationSuccess = (estimation) => {
    return {
        type: actionTypes.REMOVE_ESTIMATION_SUCCESS,
        estimation: estimation // retirer du tableau
    };
}

export const commentEstimationSuccess = (estimation) => {
    return {
        type: actionTypes.COMMENT_ESTIMATION_SUCCESS,
        estimation: estimation,
    }
}

export const fetchEstimation = (token, agent_id) => {
    return dispatch => {
        dispatch(fetchEstimationStart());
        const url = 'http://localhost:3004/agent/get-estimations';
        let urlProd = "https://api.estimateur-immo.com/agent/get-estimations";

        //const url = 'https://api.esti-immo.fr/agent/get-estimations';
        const data = JSON.stringify({"agent_id": agent_id});
        const config = {
            method: 'post',
            url: urlProd,
            headers: {
              'Content-type': 'application/json',
              Authorization: 'bearer ' + token
            },
            data : data
        };
        axios(config).then(res => {
                console.log(res.data)
                dispatch(fetchEstimationSuccess(res.data));
            })
            .catch(err => {
                console.log(err)
                dispatch(fetchEstimationFail(err));
            });
    };
};

export const removeEstimation = (token, estimation_id) => {
    return dispatch => {
        dispatch(fetchEstimationStart());

        let urlProd = "https://api.estimateur-immo.com/agent/estimation";
        const url = 'https://api.esti-immo.fr/agent/estimation';
        const config = {
            headers: {
                Authorization: 'Bearer ' + token
            },
            data: {
                estimation_id: estimation_id,
            }
        }
        axios.delete(urlProd, config).then(res => {
                console.log(res)
                dispatch(removeEstimationSuccess(estimation_id));
            })
            .catch(err => {
                console.log(err)
                dispatch(fetchEstimationFail(err));
            });
    };
}

export const commentEstimation = (token, estimation_id, comment) => {
    return dispatch => {
        dispatch(fetchEstimationStart());

        let urlProd = "https://api.estimateur-immo.com/agent/comment-estimation";
        let url = 'https://api.esti-immo.fr/agent/comment-estimation';
        const config = {
            method: 'post',
            url: urlProd,
            headers: {
              'Content-type': 'application/json',
              Authorization: 'bearer ' + token
            },
            data : {
                estimation_id: estimation_id,
                comment: comment
            }
        };
        axios(config).then(res => {
            console.log(res.data);
            dispatch(commentEstimationSuccess(res.data));
        })
        .catch(err => {
            console.log(err);
            dispatch(fetchEstimationFail(err));
        });
    }
}


