import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import { removeLeadSuccess } from '../actions';

const initialState = {
    leads: [],
    loading: false,
    error: false,
};

const fetchLeadStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const fetchLeadSuccess = ( state, action ) => {
    return updateObject( state, {
        leads: action.leads,
        loading: false
    } );
};

const fetchLeadFail = ( state, action ) => {
    return updateObject( state, { loading: false, error: false } );
};

const removeLeadSucess = ( state, action ) => {
    const index = state.leads.findIndex(element => element._id === action.lead)
    const newPc = [...state.leads]
    newPc.splice(index, 1);
    return (state , {
        leads: newPc,
        loading: false
    })
}

const commentLeadSuccess = ( state, action ) => {
    return updateObject( state, {
        leads: state.leads.map(obj => {
            return action.lead._id === obj._id ? action.lead : obj
        }),
        loading: false
    } );
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.FETCH_LEAD_START: return fetchLeadStart( state, action );
        case actionTypes.FETCH_LEAD_FAIL: return fetchLeadFail(state, action);
        case actionTypes.FETCH_LEAD_SUCCESS: return fetchLeadSuccess(state, action);
        case actionTypes.REMOVE_LEAD_SUCCESS: return removeLeadSuccess(state, action);
        case actionTypes.COMMENT_LEAD_SUCCESS: return commentLeadSuccess(state, action);
        
        default: return state;
    }
};

export default reducer;