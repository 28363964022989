import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    estimations: [],
    loading: false,
    error: false,
};

const fetchEstimationStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const fetchEstimationSuccess = ( state, action ) => {
    return updateObject( state, {
        estimations: action.estimations,
        loading: false
    } );
};

const fetchEstimationFail = ( state, action ) => {
    return updateObject( state, { loading: false } );
};

const removeEstimationSuccess = ( state, action ) => {
    const index = state.estimations.findIndex(element => element._id === action.estimation)
    const newPc = [...state.estimations]
    newPc.splice(index, 1);
    return (state , {
        estimations: newPc,
        loading: false
    })
}

const commentEstimationSuccess = ( state, action ) => {
    return updateObject( state, {
        estimations: state.estimations.map(obj => {
            return action.estimation._id === obj._id ? action.estimation : obj
        }),
        loading: false
    } );
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.FETCH_ESTIMATION_START: return fetchEstimationStart( state, action );
        case actionTypes.FETCH_ESTIMATION_FAIL: return fetchEstimationFail(state, action);
        case actionTypes.FETCH_ESTIMATION_SUCCESS: return fetchEstimationSuccess(state, action);
        case actionTypes.REMOVE_ESTIMATION_SUCCESS: return removeEstimationSuccess(state, action);
        case actionTypes.COMMENT_ESTIMATION_SUCCESS: return commentEstimationSuccess(state, action);
        
        default: return state;
    }
};

export default reducer;