import axios from 'axios'
import * as actionTypes from './actionTypes';
import moment from 'moment';

export const fetchLeadSuccess = (leads) => {
    return {
        type: actionTypes.FETCH_LEAD_SUCCESS,
        leads: leads
    };
};

export const fetchLeadFail = (error) => {
    return {
        type: actionTypes.FETCH_LEAD_FAIL,
        error: error
    };
};


export const fetchLeadStart = () => {
    return {
        type: actionTypes.FETCH_LEAD_START
    };
};

export const removeLeadSuccess = (lead) => {
    return {
        type: actionTypes.REMOVE_LEAD_SUCCESS,
        lead: lead // retirer du tableau
    };
}

export const commentLeadSuccess = (lead) => {
    return {
        type: actionTypes.COMMENT_LEAD_SUCCESS,
        lead: lead,
    }
}

export const fetchLead = (token, agent_id) => {
    return dispatch => {
        dispatch(fetchLeadStart());
        let url = 'http://localhost:3004/agent/get-leads';
        let urlProd = "https://api.estimateur-immo.com/agent/get-leads";

        //let url = 'https://api.esti-immo.fr/agent/get-leads';
        console.log('Agent ' + agent_id);
        var data = JSON.stringify({"agent_id": agent_id});
        const config = {
            method: 'post',
            url: urlProd,
            headers: {
              'Content-type': 'application/json',
              Authorization: 'bearer ' + token
            },
            data : data
        };
        axios(config).then(res => {
                res.data.forEach(e => {
                    e.createdAt = moment(e.createdAt).format('L');                 
                });
                dispatch(fetchLeadSuccess(res.data));
            })
            .catch(err => {
                console.log(err)
                dispatch(fetchLeadFail(err));
            });
    };
};

export const deleteLead = (token, lead_id) => {
    return dispatch => {
        dispatch(fetchLeadStart());
        const url = 'http://localhost:3004/agent/lead';

        let urlProd = "https://api.estimateur-immo.com/agent/lead";

        //const url = 'https://api.esti-immo.fr/agent/lead';
        const config = {
            headers: {
                Authorization: 'Bearer ' + token
            },
            data: {
                lead_id: lead_id,
            }
        }
        axios.delete(urlProd, config).then(res => {
                console.log(res)
                dispatch(removeLeadSuccess(lead_id));
            })
            .catch(err => {
                console.log(err)
                dispatch(fetchLeadFail(err));
            });
    };
}

export const commentLead = (token, lead_id, comment) => {
    return dispatch => {
        dispatch(fetchLeadStart());
        let url = 'http://localhost:3004/agent/comment-lead';
        let urlProd = "https://api.estimateur-immo.com/agent/comment-lead";

        //let url = 'https://api.esti-immo.fr/agent/comment-lead';
        const config = {
            method: 'post',
            url: urlProd,
            headers: {
              'Content-type': 'application/json',
              Authorization: 'bearer ' + token
            },
            data : {
                lead_id: lead_id,
                comment: comment
            }
        };
        axios(config).then(res => {
            console.log(res.data);
            dispatch(commentLeadSuccess(res.data));
        })
        .catch(err => {
            console.log(err);
            dispatch(fetchLeadFail(err));
        });
    }
}

